const config = {
  rootProjectId: "root",
  uiBucketName: "mmiprod.c.retter.io",
  appUrl: "https://api.mmiprod.retter.io/",
  cosUrl: "api.mmiprod.retter.io",
  version: "2.1.3",
  captchaKey: "6LfztvIpAAAAAOQ8oedG8Ah21Ku_rQyiCb6vi3cv",
  stage: "PROD"
}
export default config

